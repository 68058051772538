// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paid-version-form-js": () => import("./../../../src/pages/paid-version-form.js" /* webpackChunkName: "component---src-pages-paid-version-form-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-sheet-js": () => import("./../../../src/pages/sheet.js" /* webpackChunkName: "component---src-pages-sheet-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

